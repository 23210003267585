/* eslint-disable no-undef */

export default {
    state: {
        bx_auth: {
            domain: 'TEST_ENV',
            access_token: null,
            refresh_token: null,
            member_id: null,
            expires_in: 0
        },
        webhook: ''
    },
    mutations: {
        set_auth: (state, auth) => state.bx_auth = auth,
        set_webhook: (state, webhook) => state.webhook = webhook
    },
    actions: {
        auth({ commit }) {
            return new Promise((resolve) => {
                BX24.init(() => {
                    commit('set_auth', BX24.getAuth())
                    resolve()
                })
            })
        },
        set_webhook({ commit }, hook) {
            return new Promise((resolve) => {
                commit('set_webhook', hook)
                resolve()
            })
        }
    },
    getters: {
        auth: state => state.bx_auth,
        hook: state => state.webhook
    }
}