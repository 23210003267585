<template>
  <v-container fill-height class="d-flex align-items-center justify-center">
    <v-progress-circular size="72" indeterminate color="primary"></v-progress-circular>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Load',
  computed: { ...mapGetters(['auth']) },
  mounted() {
    this.$store.dispatch('auth').then(
      this.$router.push('/hook')
    )
  }
}
</script>

