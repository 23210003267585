const axios = require('axios')

export default {
    state: {
        donor_users: [],
        acceptor_users: [],
    },
    mutations: {
        set_donor_users: (state, users) => state.donor_users = users,
        set_acceptor_users: (state, users) => state.acceptor_users = users
    },
    actions: {
        getAcceptorUsers({commit, getters}) {
            return new Promise((resolve, reject) => {
                let hook = getters.hook
                axios.post('https://portal-migrator.mcartgroup.com/api/users/get', {
                    source: "acceptor",
                    hook,
                    all: 1,
                    //filter: {
                    //    LAST_LOGIN: {
                    //        condition: "gt",
                    //        value: "2021-05-01T00:00:00+00:00"
                    //    }
                    //}
                }).then(response => {
                    console.log("ACCEPTOR USERS RECEIVED")
                    console.log(response.data.result)
                    commit('set_acceptor_users', response.data.result)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getDonorUsers({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                let auth = getters.auth
                if (auth.access_token === null) {
                    dispatch('getAcceptorUsers').then(() => {
                        commit('set_donor_users', getters.acceptor_users) // For testing purposes
                        resolve()
                        return
                    })
                }

                axios.post('https://portal-migrator.mcartgroup.com/api/users/get', {
                    source: "donor",
                    domain: auth.domain,
                    access_token: auth.access_token,
                    refresh_token: auth.refresh_token,
                    all: 1
                }).then(response => {
                    console.log("DONOR USERS RECEIVED")
                    console.log(response.data.result)
                    commit('set_donor_users', response.data.result)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
    getters: {
        donor_users: state => state.donor_users,
        acceptor_users: state => state.acceptor_users,
    }
}