<template>
  <v-container fill-height class="d-flex align-items-center justify-center">
    <v-card min-width="600" elevation="3">
      <v-card-title class="text-h5 primary white--text">
        Миграция портала
      </v-card-title>

      <v-card-text>
        <div class="text-body-1 my-3"> Адрес портала-донора: {{ auth.domain }} </div>
        <div class="text-body-1">Введите вебхук портала-акцептора</div>
        <v-form ref="form">
          <v-text-field
            v-model="hook"
            outlined
            dense
            label="Вебхук"
            :rules="webhook_rule"
            placeholder="https://domain.ru/rest/0/webhook/"
            prepend-inner-icon="mdi-webhook"
          />
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          @click="setHook"
        > Подтвердить </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "Home",
  computed: { ...mapGetters(['auth']) },
  data: () => ({
    notification: true,
    hook: '',
    webhook_rule: [
      v => !!v || 'Поле обязательное',
      v => /^https?:\/\/[^ "]+\/rest\/\d+\/\w+\/$/.test(v) || 'Неверный формат хуки'
    ]
  }),
  mounted() {
    if (!localStorage.getItem('notification'))
      this.notification = false
      localStorage.notify = localStorage.setItem('notification', true)
  },
  methods: {
    setHook() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch(
          'set_webhook',
          this.hook
        ).then(() => {
          this.$router.push("/users/map")
        })
      }
    }
  }
};
</script>
