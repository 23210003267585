import Vue from 'vue'
import VueRouter from 'vue-router'
import HookPrompt from '../views/HookPrompt.vue'
import Load from '../views/Load.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/hook',
    name: 'HookPrompt',
    component: HookPrompt
  },
  {
    path: '/',
    name: 'Login',
    component: Load
  },
  {
    path: '/users/map',
    name: 'UsersMapping',
    component: () => import(/* webpackChunkName: "users" */ '../views/UsersMapping.vue')
  },
  {
    path: '/status',
    name: 'Status',
    component: () => import(/* webpackChunkName: "status" */ '../views/Status.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
